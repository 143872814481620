import ReactGA from 'react-ga4';
import { useState, useEffect } from 'react';

const HeartbeatTracker = () => {
  const [fiveMinuteEventSent, setFiveMinuteEventSent] = useState(false);
  const [minutesCounter, setMinutesCounter] = useState(15); // Initialize with 15 minutes

  useEffect(() => {
    // Send initial event after 5 minutes if not already sent
    if (!fiveMinuteEventSent) {
      const timer5Minutes = setTimeout(() => {
        ReactGA.event({
          category: 'Heartbeat',
          action: 'Heartbeat Event',
          label: '5 Minute Interval',
          value: 0 // Initial value
        });
        setFiveMinuteEventSent(true);
        console.log('Heartbeat Event Sent - Label: 5 Minutes');
      }, 300000); // 5 minutes in milliseconds

      return () => clearTimeout(timer5Minutes); // Clear timeout on component unmount
    }
  }, [fiveMinuteEventSent]); // Dependency on fiveMinuteEventSent

  useEffect(() => {
    // Start interval for subsequent events after the initial event
    const timer15Minutes = setInterval(() => {
      setMinutesCounter(prevCounter => prevCounter + 15);

      const intervalLabel = `${minutesCounter} minutes`;

      ReactGA.event({
        category: 'Heartbeat',
        action: 'Heartbeat Event',
        label: intervalLabel,
        value: 0 // Initial value
      });

      console.log('Heartbeat Event Sent - Label:', intervalLabel);
    }, 900000); // 15 minutes in milliseconds

    return () => clearInterval(timer15Minutes); // Clear interval on component unmount

  }, [minutesCounter]); // Dependency on minutesCounter

  return null;
};

export default HeartbeatTracker;
