import React, { useEffect } from 'react';
import ChannelForm from './ChannelForm';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const HomeComponent = () => {
  const location = useLocation();
  const { channelData } = location.state || {};

  // Send pageview event on initial load and navigation changes
  useEffect(() => {
    const sendPageview = () => {
        ReactGA.send({ hitType: 'pageview', page: '/home' });
        console.log('Send /home pageview');
      };

    sendPageview();

    const unlisten = () => {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
      console.log(`Send ${location.pathname} pageview`);
    };

    window.addEventListener('popstate', unlisten);

    return () => {
      window.removeEventListener('popstate', unlisten);
    };
  }, [location.pathname]);

  const handleSubmit = () => {
    ReactGA.send({ hitType: 'event', eventCategory: 'ChannelForm', eventAction: 'Form Submitted' });
  };

  return (
    <div className="home-container">
      <div className='intro'>
        <h1>Watch multiple Twitch & YouTube live streams at the same time.</h1>
        <p>Enter Twitch or YouTube channel names you want to watch.</p>
      </div>
      <ChannelForm channelData={channelData} handleSubmit={handleSubmit} />
      {/* Add TwitchLoginButton component */}
      {/* <TwitchLoginButton /> */}
      <div className='messages'>
        <p>* <span>Twitch & YouTube</span> use cross-site tracking to share your logged-in account across windows and tabs. Some browsers (hello Safari!) prevent cross-site tracking by default, so you may appear logged out.</p>
      </div>
      <div className='version-track'>
        <p>{process.env.REACT_APP_VERSION}</p>
      </div>
    </div>
  );
};

export default HomeComponent;
