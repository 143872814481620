import React, { useState, useEffect, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import TwitchVideoComponent from './TwitchVideo';
import TwitchChatComponent from './TwitchChat';
import YouTubeVideoComponent from './YouTubeVideo';
import YouTubeChatComponent from './YouTubeChat';
import ChannelForm from './ChannelForm';
import ReactGA from 'react-ga4';
import HeartbeatTracker from './heartbeatTracker';

const StreamComponent = memo(({ channelData, isLoaded }) => {
  const sortedChannelData = channelData.sort((a, b) => a.order - b.order);
  const numChannels = sortedChannelData.length;
  const numChannelsWord = numChannels === 1 ? 'one' : numChannels === 2 ? 'two' : numChannels === 3 ? 'three' : numChannels === 4 ? 'four' : numChannels === 5 ? 'five' : 'six';
  const chatOnly = JSON.parse(sessionStorage.getItem('chatOnly'));
  const renderAllChats = numChannels > 3;
  const [activeTab, setActiveTab] = useState(renderAllChats ? 0 : null);
  const [showChannelForm, setShowChannelForm] = useState(false);
  const navigate = useNavigate();
  const [hideChats, setHideChats] = useState(() => {
    const storedHideChats = sessionStorage.getItem('hideChats');
    return storedHideChats ? JSON.parse(storedHideChats) : false;
  });
  const [focusMode, setFocusMode] = useState(() => {
    const storedFocusMode = sessionStorage.getItem('focusMode');
    return storedFocusMode ? JSON.parse(storedFocusMode) : false;
  });
  const [isChannelsLoaded, setIsChannelsLoaded] = useState(false);
  const [activeVideoTab, setActiveVideoTab] = useState(0);

  useEffect(() => {
    // Load focus mode from sessionStorage on component mount
    const storedFocusMode = sessionStorage.getItem('focusMode');
    const parsedFocusMode = storedFocusMode ? JSON.parse(storedFocusMode) : false;
    setFocusMode(parsedFocusMode);
  
    // Apply or remove 'focus-mode' class based on focus mode state
    const streamsContainer = document.querySelector('.streams-container');
    if (streamsContainer && parsedFocusMode) {
      streamsContainer.classList.add('focus-mode');
    } else {
      streamsContainer.classList.remove('focus-mode');
    }
  }, []);
  
  // Function to toggle focus mode
  const toggleFocusMode = useCallback(() => {
    const updatedFocusMode = !focusMode;
    setFocusMode(updatedFocusMode);
    sessionStorage.setItem('focusMode', JSON.stringify(updatedFocusMode));

    // Log the event details to the console for debugging
    console.log('Sending GA event:', {
      category: 'StreamComponent Interaction',
      action: `Toggled Focus Mode: ${updatedFocusMode ? 'Enter' : 'Exit'}`,
    });
    
    // Track the event when the "Focus Mode" or "Exit Focus Mode" button is clicked
    ReactGA.event({
      category: 'StreamComponent Interaction',
      action: `Toggled Focus Mode: ${updatedFocusMode ? 'Enter' : 'Exit'}`,
    });
  }, [focusMode, setFocusMode]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the key pressed is the 'f' key
      if (event.target.tagName !== "INPUT" && event.target.tagName !== "TEXTAREA") {
        if (event.key === 'f') {
          // Call toggleHideChats function
          toggleFocusMode();
        }
      }
    };

    // Add event listener when component mounts
    window.addEventListener('keydown', handleKeyDown);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [toggleFocusMode]);
  
  useEffect(() => {
    const handleResize = () => {
      // If window width goes below 768 pixels, set session storage value to false
      if (window.innerWidth < 768) {
        sessionStorage.setItem('focusMode', JSON.stringify(false));
        setFocusMode(false);
      }
    };

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleHideChats = useCallback(() => {
    const updatedHideChats = !hideChats;
    setHideChats(updatedHideChats);
    sessionStorage.setItem('hideChats', JSON.stringify(updatedHideChats));
    
    // Log the event details to the console for debugging
    console.log('Sending GA event:', {
      category: 'StreamComponent Interaction',
      action: `Toggled Hide Chats: ${updatedHideChats ? 'Hide' : 'Show'}`,
    });
    
    // Track the event when the "Show Chats" or "Hide Chats" button is clicked
    ReactGA.event({
      category: 'StreamComponent Interaction',
      action: `Toggled Hide Chats: ${updatedHideChats ? 'Hide' : 'Show'}`,
    });
  }, [hideChats, setHideChats]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the key pressed is the 'c' key
      if (event.target.tagName !== "INPUT" && event.target.tagName !== "TEXTAREA") {
        if (event.key === 'c') {
          // Call toggleHideChats function
          toggleHideChats();
        }
      }
    };

    // Add event listener when component mounts
    window.addEventListener('keydown', handleKeyDown);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [toggleHideChats]);

  // Function to track the event when the "Menu" button is clicked
  const trackMenuButtonClick = () => {
    // Log the event details to the console for debugging
    console.log('Sending GA event:', {
      category: 'StreamComponent Interaction',
      action: 'Menu Button Clicked',
    });

    // Track the event when the "Menu" button is clicked
    ReactGA.event({
      category: 'StreamComponent Interaction',
      action: 'Menu Button Clicked',
    });
  };

  useEffect(() => {
    if (chatOnly) {
      // Track the event when chatOnlyMode is enabled
      ReactGA.event({
        category: 'StreamComponent Interaction',
        action: 'Chat Only Mode Enabled',
      });
    }
  }, [chatOnly]);

  // Function to track the number of channels loaded
  const trackChannelsLoaded = (numChannels) => {
    if (numChannels > 0) {
      // Log the number of channels loaded to the console
      console.log(`Channels loaded: ${numChannels}`);
      
      // Track the event with the appropriate label based on the number of channels loaded
      ReactGA.event({
          category: 'Channels',
          action: 'Channels Loaded',
          label: 'Number of Channels',
          value: numChannels
      });
      setIsChannelsLoaded(true);
    }
  };

  const trackStreamsTitleLinkClick = () => {
    // Track the event when the "Streams" link is clicked
    ReactGA.event({
      category: 'Stream Title Button Interaction',
      action: 'Streams Title Link Clicked',
    });
  };

  useEffect(() => {
    // Track the number of channels loaded on component mount
    const numChannels = channelData.length;
    trackChannelsLoaded(numChannels);
  }, [channelData]);

  useEffect(() => {
    const handleBrowserBack = () => {
      navigate.replace('/', { channelData });
    };

    window.addEventListener('popstate', handleBrowserBack);

    return () => {
      window.removeEventListener('popstate', handleBrowserBack);
    };
  }, [navigate, channelData]);

  useEffect(() => {
    const isMobile = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      return /mobile/.test(userAgent);
    };

    if (isMobile()) {
      setHideChats(true);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 768 || window.innerHeight < 479;
      const shouldHideChats = (isMobile && numChannels > 1) || sessionStorage.getItem('hideChats') === 'true';
      setHideChats(shouldHideChats);
    };

    const shouldHideChats = !chatOnly || (numChannels === 1 && !chatOnly);
    setHideChats(shouldHideChats);

    if (numChannels === 1 && !chatOnly) {
      sessionStorage.setItem('hideChats', JSON.stringify(false));
    }

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [numChannels, chatOnly]);

  useEffect(() => {
    if (hideChats) {
      document.documentElement.classList.add('hide-chat');
    } else {
      document.documentElement.classList.remove('hide-chat');
    }
  }, [hideChats]);
  

  // Chat Tabs
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    // Set the active tab to the index of the first tab on initial load
    setActiveTab(0);
  }, []);

  // Video Tabs
  const handleVideoTabClick = (index) => {
    setActiveVideoTab(index);
  };

  useEffect(() => {
    // Set the active tab to the index of the first tab on initial load
    setActiveVideoTab(0);
  }, []);

  // User 1-6 on the keyboard to change the video tab focus. Limited based on number of channels loaded in channleData.
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if focusMode is true and if the pressed key is a number between 1 and the number of channels loaded
      if (focusMode) {
        if (event.target.tagName !== "INPUT" && event.target.tagName !== "TEXTAREA") {
          const keyNumber = parseInt(event.key);
          const maxTabIndex = channelData.length; // Calculate the maximum allowed index based on the number of channels
          if (!isNaN(keyNumber) && keyNumber >= 1 && keyNumber <= maxTabIndex) {
            // Adjust keyNumber to match array index (since array indices start from 0)
            const tabIndex = keyNumber - 1;
            // Set the active video tab to the corresponding index
            setActiveVideoTab(tabIndex);
          }
        }
      }
    };
  
    // Add event listener for keydown if focusMode is true
    if (focusMode) {
      window.addEventListener('keydown', handleKeyDown);
    }
  
    // Clean up event listener when component unmounts or when focusMode changes to false
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [setActiveVideoTab, channelData, focusMode]);

  // User 1-6 on the keyboard to change the chat tab focus. Limited based on number of channels loaded in channleData.
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if focusMode is true and if the pressed key is a number between 1 and the number of channels loaded
      if (
        event.target.tagName !== "INPUT" &&
        event.target.tagName !== "TEXTAREA" &&
        event.ctrlKey // Check if the Crtl key is pressed
      ) {
        const keyNumber = parseInt(event.key);
        const maxTabIndex = channelData.length; // Calculate the maximum allowed index based on the number of channels
        if (!isNaN(keyNumber) && keyNumber >= 1 && keyNumber <= maxTabIndex) {
          // Log that the key press is being read
          // console.log(`Crtl + ${keyNumber} pressed. Setting active tab index...`);
          // Adjust keyNumber to match array index (since array indices start from 0)
          const tabIndex = keyNumber - 1;
          // Set the active tab index to the corresponding index using handleTabClick
          handleTabClick(tabIndex);
        }
      }
    };
  
    // Add event listener for keydown if there are four or more channels
  if (channelData.length >= 4) {
    window.addEventListener("keydown", handleKeyDown);
  }
  
    // Clean up event listener when component unmounts or when focusMode changes to false
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [channelData]);

  const toggleChannelForm = () => {
    setShowChannelForm(!showChannelForm);

    // Call the function to track the event when the "Menu" button is clicked
    trackMenuButtonClick();
  };

  useEffect(() => {
    console.log('Channels are done loading', isChannelsLoaded);
    if (isChannelsLoaded) {
      ReactGA.send({ hitType: 'pageview', page: '/stream' });
      console.log('Send /stream pageview');
    }
  }, [isChannelsLoaded]);

  return (
    <React.Fragment>
      <HeartbeatTracker />
      <div className={`stream-controls ${numChannelsWord}-${numChannels === 1 ? 'channel' : 'channels'}`}>
        <div className='the-controls'>
          {numChannels > 1 && (
            <button className="focus-mode-toggle" onClick={toggleFocusMode} title={focusMode ? 'Exit Focus Mode (f)' : 'Focus Mode (f)'}>
              {focusMode ? 'Exit Focus Mode' : 'Focus Mode'}
            </button>
          )}
          {!chatOnly && (
            <button onClick={toggleHideChats} className={`the-chat-toggle ${hideChats ? 'hide' : ''}`} title={hideChats ? 'Show Chat (c)' : 'Hide Chat (c)'}>
              {channelData.length === 1 ? (hideChats ? 'Show Chat' : 'Hide Chat') : (hideChats ? 'Show Chats' : 'Hide Chats')}
            </button>
          )}
          <button className="the-menu" onClick={toggleChannelForm} title='Menu'>Menu</button>
        </div>
        <div className='the-name'>
          <a href="/" onClick={trackStreamsTitleLinkClick}><h1>Streams</h1></a>
        </div>
      </div>
      <div className={`streams-container ${numChannelsWord}-${numChannels === 1 ? 'channel' : 'channels'} ${hideChats ? 'hide-chat' : ''} ${focusMode ? 'focus-mode' : ''} ${chatOnly ? 'chat-only' : ''} ${hideChats ? '' : 'chats-open'}`}>
        
        {showChannelForm && <ChannelForm initialChannels={channelData} buttonLabel="Update" />}

        <div className="channel-container the-videos">
          {sortedChannelData.map((channel, index) => {
            const componentKey = `${channel.type}-${channel.username}-${index}`;
            const componentProps = { username: channel.username, videoID: channel.videoID };

            return (
              <React.Fragment key={componentKey}>
                {channel.type === 'twitch' ? (
                  <TwitchVideoComponent {...componentProps} focus={index === activeVideoTab} />
                ) : (
                  <YouTubeVideoComponent {...componentProps} focus={index === activeVideoTab} />
                )}
              </React.Fragment>
            );
          })}

          {focusMode && ( // Conditionally render the <ul> element in focus mode
            <div className="video-tabs">
              {sortedChannelData.map((channel, index) => (
                <div key={index} className={`tab ${index === activeVideoTab ? 'active' : ''} ${channel.type === 'twitch' ? 'twitch' : 'youtube'}`} onClick={() => handleVideoTabClick(index)} title={`${channel.username} Stream (${index + 1})`}>
                  <span>{channel.username}</span>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="channel-container the-chats">
          {!chatOnly && renderAllChats && (
            <div className="tabs">
              {sortedChannelData.map((channel, index) => (
                <div key={index} className={`tab ${index === activeTab ? 'active' : ''} ${channel.type === 'twitch' ? 'twitch' : 'youtube'}`} onClick={() => handleTabClick(index)} title={`${channel.username} Chat (ctrl + ${index + 1})`}>
                  <span>{channel.username}</span>
                </div>
              ))}
            </div>
          )}

          {sortedChannelData.map((channel, index) => {
            const componentKey = `${channel.type}-${channel.username}-${index}`;
            const componentProps = { username: channel.username, videoID: channel.videoID };

            return (
              <React.Fragment key={componentKey}>
                {channel.type === 'twitch' ? (
                  <TwitchChatComponent key={componentKey} {...componentProps} active={index === activeTab} />
                ) : (
                  <YouTubeChatComponent key={componentKey} {...componentProps} active={index === activeTab} />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <div className='stream-background'></div>
    </React.Fragment>
  );
});

export default StreamComponent;