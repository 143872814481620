import React, { memo } from 'react';

const YouTubeChatComponent = memo(({ username, videoID, active }) => {
  console.log("YT Video ID:", videoID)
  return (
    <div className={`chat-container youtube ${active ? 'active' : ''}`} id={username}>
      <div className='chat-banner'>
        <p>{username}</p>
      </div>
      {videoID ? (
        <>
            {/* Render YouTube chat iframe */}
            <iframe
                src={`https://www.youtube.com/live_chat?v=${videoID}&embed_domain=${window.location.hostname}&dark_theme=1`}
                title={`YouTube Live Chat - ${username}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </>
      ) : (
        // Render offline message for offline channel
        <div className='channel-offline'>
          <div className='offline-message'>
            <p className='channel-name'>{`${username}`}</p>
            <p>Channel is offline</p>
          </div>
        </div>
      )}
    </div>
  );
});

export default YouTubeChatComponent;