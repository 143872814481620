import React, { memo } from 'react';

const TwitchChatComponent = memo(({ username, active }) => {
  return (
    <div className={`chat-container twitch ${active ? 'active' : ''}`} id={username}>
      {/* Render Twitch chat iframe */}
        <div className='chat-banner'>
          <p>{username}</p>
        </div>
        <iframe
          src={`https://www.twitch.tv/embed/${username}/chat?darkpopout&parent=${process.env.REACT_APP_PATENT_TWITCH_DOMAIN}`}
          title={`Twitch Live Chat - ${username}`}
          frameBorder="0"
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-modals"
        ></iframe>
    </div>
  );
});

export default TwitchChatComponent;