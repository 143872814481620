import React from 'react';

const AboutPage = () => {
  return (
    <article className='about'>
      <h1>About Watch Streams</h1>
      <p>Experience seamless multistream viewing with Watch Streams, designed to let you watch Twitch and YouTube live streams simultaneously. Perfect for fans and streamers alike, our service offers customizable layouts, chat-only mode to keep track of multiple chat rooms across platforms for broadcasters, and the ability to watch your favorite streamers or gaming partners all in one screen. Whether you're multitasking, hosting collaborations, or engaging with communities, our tool makes it easy to stay connected with Twitch and YouTube live streams at the same time.</p>
    </article>
  );
};

export default AboutPage;
