import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import RoutesConfig from './Routes';
import MetaTags from "./HeadMetaTags";
import './styles/main.scss';
import axios from 'axios';
import ReactGA from 'react-ga4';

const App = () => {
  const [isIOSiPhoneSafari, setIsIOSiPhoneSafari] = useState(false);
  const [isIOSiPadSafari, setIsIOSiPadSafari] = useState(false);
  const [channelData, setChannelData] = useState([]);
  const [chatOnly, setChatOnly] = useState(false);
  const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const initializeGA = () => {
      const isProduction = process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging';
      if (isProduction) {
        console.log('GA Active');
        ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
          // debug_mode: true,
          gaOptions: {
            debug_mode: true,
            // send_page_view: false,
          },
          gtagOptions: {
            debug_mode: true,
          },
        });
      }
    };
    initializeGA();
  }, []);

  useEffect(() => {
    const checkIOSDevice = () => {
      const userAgent = navigator.userAgent;
      if (userAgent.includes("iPad") && userAgent.includes("Safari")) {
        setIsIOSiPadSafari(true);
      } else if (userAgent.includes("iPhone") && userAgent.includes("Safari")) {
        setIsIOSiPhoneSafari(true);
      }
    };
    checkIOSDevice();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    // Check if the 'code' parameter is present in the URL
    const code = urlParams.get('code');
    if (code) {
      // Save the 'code' parameter to local storage
      // localStorage.setItem('twitchAuthCode', code);
    }
  
    // Continue parsing other parameters
    const isChatOnly = urlParams.get('chatOnly');
    if (isChatOnly === 'true') {
      setChatOnly(true);
      sessionStorage.setItem('chatOnly', 'true');
    } else if (isChatOnly === 'false') {
      setChatOnly(false);
      sessionStorage.setItem('chatOnly', 'false');
    }
  }, []);

  const parseChannels = (urlUsernames) => {
    const youtubeChannels = [];
    const twitchChannels = [];
    urlUsernames.forEach((username, index) => {
      const channelName = username.substring(1);
      const order = index + 1;
      if (username.startsWith('y')) {
        youtubeChannels.push({ username: channelName, type: 'youtube', order });
      } else if (username.startsWith('t')) {
        twitchChannels.push({ username: channelName, type: 'twitch', order });
      }
    });
    return { youtubeChannels, twitchChannels };
  };

  const fetchYouTubeData = async (youtubeChannels) => {
    if (!youtubeChannels) return [];

    const responses = await Promise.all(youtubeChannels.map(async (channel) => {
      const storedVideoID = localStorage.getItem(`youtubeVideoID-${channel.username}`);
      const storedExpiration = localStorage.getItem(`youtubeVideoIDExpiration-${channel.username}`);
      if (storedVideoID && parseInt(storedExpiration) > Date.now()) {
        return {
          username: channel.username,
          videoID: storedVideoID,
          type: 'youtube',
          order: channel.order,
        };
      } else {
        try {
          const response = await axios.get(`${process.env.REACT_APP_YOUTUBE_SERVER}/api/live-status`, {
            params: { username: channel.username },
          });
          const videoID = response.data.videoID || null;
          if (videoID !== null) {
            localStorage.setItem(`youtubeVideoID-${channel.username}`, videoID);
            localStorage.setItem(`youtubeVideoIDExpiration-${channel.username}`, Date.now() + 15 * 60 * 1000);
          }
          return {
            username: channel.username,
            videoID,
            type: 'youtube',
            order: channel.order,
          };
        } catch (error) {
          console.error('Error fetching live status for YouTube channel:', channel.username, error);
          return {
            username: channel.username,
            videoID: null,
            type: 'youtube',
            order: channel.order,
          };
        }
      }
    }));

    return responses.filter(Boolean);
  };

  useEffect(() => {
    const fetchData = async () => {
      const urlUsernames = location.pathname.split('/').filter(Boolean);
      const { youtubeChannels, twitchChannels } = parseChannels(urlUsernames);
      const youtubeData = await fetchYouTubeData(youtubeChannels);

      const twitchData = twitchChannels.filter(channel => channel.type === 'twitch').map(channel => ({
        username: channel.username,
        type: 'twitch',
        order: channel.order,
      }));

      setChannelData([...youtubeData, ...twitchData]);
    };
    fetchData();
    setIsLoaded(true);
  }, [location.pathname]);

  return (
    <div className={`watch-streams ${isIOSiPhoneSafari ? 'ios-iphone-safari' : ''} ${isIOSiPadSafari ? 'ios-ipad-safari' : ''}`}>
      <MetaTags channelData={channelData}/>
      <RoutesConfig channelData={channelData} chatOnly={chatOnly} isLoaded={isLoaded} />
    </div>
  );
};

export default App;
