import React, { memo } from 'react';

const YouTubeVideoComponent = memo(({ username, videoID, focus }) => {
  console.log("YT Video ID:", videoID)
  return (
    <div className={`player-container ${focus ? 'focus' : ''}`} id={username}>
      {videoID ? (
        <>
            <iframe
            src={`https://www.youtube.com/embed/${videoID}`}
            title={`YouTube Live Stream - ${username}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            ></iframe>
        </>
      ) : (
        // Render offline message for offline channel
        <div className='channel-offline'>
          <div className='offline-message'>
            <p className='channel-name'>{`${username}`}</p>
            <p>is offline</p>
          </div>
        </div>
      )}
    </div>
  );
});

export default YouTubeVideoComponent;