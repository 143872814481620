import React, { memo } from 'react';

const TwitchVideoComponent = memo(({ username, focus }) => {
  return (
    <div className={`player-container ${focus ? 'focus' : ''}`} id={username}>
        {/* <div className='video-container'> */}
          <iframe
            src={`https://player.twitch.tv/?channel=${username}&parent=${process.env.REACT_APP_PATENT_TWITCH_DOMAIN}`}
            title={`Twitch Live Stream - ${username}`}
            frameBorder="0"
            scrolling="no"
            allowFullScreen
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-modals"
          ></iframe>
        {/* </div> */}
    </div>
  );
});

export default TwitchVideoComponent;