import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Add this line to import axios

const Callback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const searchParams = new URLSearchParams(window.location.search);
        const code = searchParams.get('code');
        console.log(window.location.search)

        if (code) {
          // Exchange the code for an access token using the Twitch API
          const response = await axios.post(`${process.env.REACT_APP_TWITCH_OAUTH_SERVICE}/twitch/token`, {
            code,
            // Add any additional required parameters
          });

          // Extract the access token from the response
          const accessToken = response.data.access_token;

          // Store the access token in local storage
          localStorage.setItem('accessToken', accessToken);

          // Redirect the user to the home page while preserving URL parameters
          navigate({ pathname: '/', search: window.location.search });

        } else {
          console.error('Authorization code not found in callback URL');
        }
      } catch (error) {
        console.error('Error extracting access token:', error);
      }
    };
    
    fetchAccessToken();
  }, [navigate]);

  return (
    <div>
      <h2>Processing...</h2>
    </div>
  );
};

export default Callback;