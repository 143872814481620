import React from 'react';

const TwitchLoginButton = () => {
  const handleTwitchLogin = () => {
    // Redirect the user to your Node.js server's authentication endpoint
    window.location.href = `${process.env.REACT_APP_TWITCH_OAUTH_SERVICE}/auth/twitch`; // Replace with your server's URL
  };

  return (
    <div className='login-button'>
      <button onClick={handleTwitchLogin}>Connect with Twitch</button>
    </div>
  );
};

export default TwitchLoginButton;
