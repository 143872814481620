import React, { useState, useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';

const ChannelForm = ({ initialChannels, buttonLabel }) => {
  const [channels, setChannels] = useState(initialChannels || [{ prefix: 't', name: '' }]);
  const [chatOnly, setChatOnly] = useState(false);
  const [focusedInput, setFocusedInput] = useState(null);
  const [addChannelClicked, setAddChannelClicked] = useState(false); // New state for tracking if add channel button is clicked
  const inputRefs = useRef([]);
  const [errorMessage, setErrorMessage] = useState("");

  // Effect to focus the first input element when the component mounts
  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  // Function to send GA event when form is submitted
  const sendGAEvent = () => {
    ReactGA.event({
      category: 'Channel Form Submission',
      action: 'Form Submitted',
      label: 'Channels Count',
      value: channels.length
    });
  };

  // Effect to populate form fields if initialChannels are provided
  useEffect(() => {
    if (initialChannels && initialChannels.length > 0) {
      const initialFormData = initialChannels.map(channel => ({
        prefix: channel.type === 'twitch' ? 't' : 'y',
        name: channel.username,
      }));
      setChannels(initialFormData);
    }
  }, [initialChannels]);

  const handleSubmit = (event) => {
    event.preventDefault();
    sendGAEvent();
    const formattedChannels = channels
      .filter(channel => channel.name.trim() !== '')
      .map(channel => `${channel.prefix}${channel.name}`).join('/');
  
    if (chatOnly) {
      const url = new URL(window.location.href);
      url.searchParams.set('chatOnly', 'true');
      window.location.href = `${process.env.REACT_APP_ROOT_DOMAIN}/${formattedChannels}${url.search}`;
    } else {
      window.location.href = `${process.env.REACT_APP_ROOT_DOMAIN}/${formattedChannels}`;
    }
  };

  const togglePrefix = (index) => {
    const updatedChannels = [...channels];
    updatedChannels[index].prefix = channels[index].prefix === 't' ? 'y' : 't';
    setChannels(updatedChannels);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    let updatedValue = value.trim(); // Remove leading and trailing spaces
    
    // Check if the input value is a URL
    if (updatedValue.startsWith("http://") || updatedValue.startsWith("https://")) {
      // Extract channel name from Twitch URL
      if (updatedValue.includes("twitch.tv")) {
        const channelName = updatedValue.split("twitch.tv/")[1];
        updatedValue = channelName;
      } 
      // Extract channel name from YouTube URL
      else if (updatedValue.includes("@")) {
        const channelName = updatedValue.split("@")[1].split("/")[0];
        updatedValue = channelName;
      }
      // If it's a URL but not recognized, set it to an empty string
      else {
        setErrorMessage("Enter channel names, not channel URLs.");
        updatedValue = "";
      }
    }
  
    const updatedChannels = [...channels];
    updatedChannels[index][name] = updatedValue;
    
    // Limit the number of channels to less than 6
    if (updatedChannels.length <= 6) {
      setChannels(updatedChannels);
    }
  };

  const handleAddTwitchChannel = () => {
    if (channels.length < 6) {
      setChannels(prevChannels => [...prevChannels, { prefix: 't', name: '' }]);
      setFocusedInput(channels.length);
      setAddChannelClicked(true); // Set add channel clicked to true
    }
  };

  const handleAddYouTubeChannel = () => {
    if (channels.length < 6) {
      setChannels(prevChannels => [...prevChannels, { prefix: 'y', name: '' }]);
      setFocusedInput(channels.length);
      setAddChannelClicked(true); // Set add channel clicked to true
    }
  };

  const handleRemoveChannel = (index) => {
    if (channels.length > 1) {
      const updatedChannels = [...channels];
      updatedChannels.splice(index, 1);
      setChannels(updatedChannels);

      if (focusedInput === index) {
        setFocusedInput(null);
      }
    }
  };

  const handleInputFocus = (index) => {
    setFocusedInput(index);
  };

  const toggleChatOnly = (event) => {
    const newChatOnly = !chatOnly;
    setChatOnly(newChatOnly);
    sessionStorage.setItem('chatOnly', newChatOnly.toString());
  };

  useEffect(() => {
    const storedChatOnly = sessionStorage.getItem('chatOnly');
    if (storedChatOnly !== null) {
      setChatOnly(JSON.parse(storedChatOnly));
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Filter out null elements from inputRefs.current
      const validRefs = inputRefs.current.filter(ref => ref !== null);
  
      // Check if any of the valid refs contain the event target
      if (!validRefs.some(ref => ref.contains(event.target))) {
        setFocusedInput(null);
      }
    };
  
    document.body.addEventListener('click', handleClickOutside);
  
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (focusedInput !== null && addChannelClicked && inputRefs.current.length > 0) {
      inputRefs.current[focusedInput]?.focus();
      setAddChannelClicked(false); // Reset add channel clicked state
    }
  }, [channels, addChannelClicked, focusedInput]);

  return (
    <div className="channel-form">
      <form onSubmit={handleSubmit} className='channel-list' autoComplete="off" autoCapitalize="off">
        {channels.map((channel, index) => (
          <div key={index} className='channel-list-item'>
            <button
              type="button"
              className={`channel-toggle ${channel.prefix === 't' ? 'twitch' : 'youtube'}`}
              onClick={() => togglePrefix(index)}
              tabIndex={index * 4 + 1} 
            >
              {channel.prefix}
            </button>
            <input
              ref={el => inputRefs.current[index] = el}
              type="text"
              name="name"
              value={channel.name || ''}
              onChange={(event) => handleChange(index, event)}
              placeholder={`Channel Name ${index + 1}`}
              className={`input ${channel.prefix === 't' ? 'twitch' : 'youtube'}`}
              onFocus={() => handleInputFocus(index)}
              tabIndex={index * 4 + 2} 
            />
            {channels.length > 1 && (
              <button type="button" className='remove-channel' onClick={() => handleRemoveChannel(index)} tabIndex={index * 4 + 3}>-</button>
            )}
          </div>
        ))}
        {errorMessage && <div className='form-error'><p>{errorMessage}</p></div>}
        <div className='add-channels'>
          {channels.length < 6 && (
            <>
              <button type="button" className='twitch' onClick={handleAddTwitchChannel} tabIndex={channels.length * 4 + 1}>+t</button>
              <button type="button" className='youtube' onClick={handleAddYouTubeChannel} tabIndex={channels.length * 4 + 2}>+y</button>
            </>
          )}
        </div>
  
        <div className='layout-options'>
          <label htmlFor="toggleChatOnly" className='label' tabIndex={channels.length * 4 + 3}>Chat Only Mode</label>
          <label className='toggle-switch'>
            <input
              type="checkbox"
              id="toggleChatOnly"
              checked={chatOnly}
              onChange={toggleChatOnly}
              tabIndex={channels.length * 4 + 4}
            />
            <span className='slider round' tabIndex={channels.length * 4 + 5}></span>
          </label>
        </div>
  
        <div className='start-watching'>
          <button type="submit" tabIndex={channels.length * 4 + 6}>{buttonLabel || "Start Watching"}</button>
        </div>
      </form>
    </div>
  );
};

export default ChannelForm;