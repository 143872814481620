import React from 'react';

const ErrorPage = () => {
  return (
    <div>
      <h1>Error Page</h1>
      <p>Sorry, an error occurred.</p>
      {/* You can customize the error message or display additional information here */}
    </div>
  );
};

export default ErrorPage;
